import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function HumanMessage({ text }) {
  return (
    <div className="flex items-start justify-center">
      <div className="text-electric-violet-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-8 w-8 mr-4 mt-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </div>
      <div className="bg-electric-violet-100 text-electric-violet-800 py-4 px-4 w-full rounded-md min-h-[60px]">
        {text}
      </div>
    </div>
  );
}

function AIMessage({ text }) {
  return (
    <div className="flex items-start justify-center">
      <img src="animatraiAvatar.png" alt="logo" className="h-8 w-8 m-0 mt-2 mr-4" />
      <div className="bg-gray-100 px-4 w-full rounded-md min-h-[60px]">
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {text}
        </ReactMarkdown>
      </div>
    </div>
  );
}

function Message({ name, text, thinking }) {
  return (
    <div className="w-full min-h-[40px] text-gray-800 rounded-md mb-4">
      {name === "ai" ? <AIMessage text={text} /> : <HumanMessage text={text} />}
    </div>
  );
}

export { Message };
