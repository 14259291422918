import ScrollToBottom from "react-scroll-to-bottom";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect, useReducer, useRef, KeyboardEvent } from "react";
import { strings } from "./constants";
import { Message } from "./message";
import SendIcon from "./sendIcon";
import Spinner from "./spinner";
const Settings = require("../../../src/settings.json");

function reducer(state, action) {
    switch (action.type) {
        case "addMessage":
            return {
                ...state,
                assistantThinking: true,
                messages: [...state.messages, { name: "human", text: action.payload.prompt }, { name: "ai", text: "" }],
                controller: action.payload.controller,
            };
        case "updatePromptAnswer":
            console.log("updatePromptAnswer..");
            console.log("payload:");
            console.log(action.payload);

            const conversationListCopy = [...state.messages];
            const lastIndex = conversationListCopy.length - 1;
            conversationListCopy[lastIndex] = {
                ...conversationListCopy[lastIndex],
                text: /*conversationListCopy[lastIndex].text +*/ action.payload,
            };
            return {
                ...state,
                assistantThinking: false,
                isWriting: true,
                messages: conversationListCopy,
            };
        case "handleError":
            console.error("An error occurred:", action.payload);

            const lastMessage = state.messages[state.messages.length - 1];
            let messages = [...state.messages];

            if(lastMessage.name === 'ai' && lastMessage.text === ''){
                messages.pop();
            }

            return {
                ...state,
                assistantThinking: false,
                isWriting: false,
                controller: null,
                messages: [...messages, { name: "ai", text: 'Chat error. Please ask AnimatrAI to "Try again please".' }],
            };
        case "abort":
            state.controller?.abort();
            return {
                ...state,
                isWriting: false,
                assistantThinking: false,
                controller: null,
            };
        case "done":
            return {
                ...state,
                isWriting: false,
                assistantThinking: false,
                controller: null,
            };
        default:
            return state;
    }
}

/*
 * Chat component
 * Uses api/chat/generateChatReponse to generate video prompt thorugh a series of questions and answers
 * @param {function} onFinalResult - callback function to be called with a data object payload when the chat is done
 * see animtr-backend/model/chat.js chatResultJSONSchema for the data object structure
 */
export default function Chat(props) {
    if (!props.onFinalResult) {
        console.error("Chat component requires onFinalResult prop");
    }

    const [state, dispatch] = useReducer(reducer, {
        messages: [
            {
                name: "ai",
                text: strings.uiLabels.startingMessage,
            },
        ],
        assistantThinking: false,
        isWriting: false,
        controller: null,
    });

    const promptInput = useRef(null);

    const handlePrompt = async () => {
        if (promptInput && promptInput.current) {
            const prompt = promptInput.current.value;
            if (prompt !== "") {
                const controller = new AbortController();
                const signal = controller.signal;
                dispatch({ type: "addMessage", payload: { prompt, controller } });
                promptInput.current.value = "";

                console.log("body:", JSON.stringify({ messages: state.messages, prompt }));

                const baseUrl = Settings[process.env.NODE_ENV].video_server_url;
                try {
                    const res = await fetch(`${baseUrl}api/chat/generateChatReponse`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ messages: state.messages, prompt }),
                        signal: signal,
                    });

                    if (res.ok) {
                        console.log("res ok");
                        const data = await res.json();
                        console.log("data:", data);

                        if (data.isLastMessage) {
                            // add ending message
                            dispatch({ type: "updatePromptAnswer", payload: strings.uiLabels.endingMessage });
                            dispatch({ type: "done" });
                            props.onFinalResult(data.data);
                        } else {
                            dispatch({ type: "updatePromptAnswer", payload: data.message });
                            dispatch({ type: "done" });
                        }
                    } else {
                        dispatch({ type: "handleError", payload: res.statusText });
                    }
                } catch (error) {
                    dispatch({ type: "handleError", payload: error.message });
                }
                finally {
                  promptInput?.current?.focus();
                }
            }
        }
    };

    const handlePromptKey = async (e) => {
        e.stopPropagation();
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handlePrompt();
        }
    };

    const handleAbort = () => {
        dispatch({ type: "abort" });
    };

    // focus input on page load
    useEffect(() => {
        if (promptInput && promptInput.current) {
            promptInput.current.focus();
        }
    }, []);

    return (
        <div className="flex relative justify-center flex-1 ">
            <main className="bg-white max-w-3xl m-0 md:rounded-xl relative transition-width flex flex-col overflow-hidden items-stretch  font-default">
                <div className="flex-1 overflow-hidden ">
                    <ScrollToBottom
                        className="relative pt-6 pb-12 mx-4  h-[calc(100vh-300px)] min-h-[500px]"
                        scrollViewClassName="overflow-y-auto"
                    >
                        <div className="w-full transition-width flex flex-col items-stretch flex-1">
                            <div className="flex-1">
                                <div className="flex flex-col prose prose-lg prose-invert">
                                    {state.messages.map((message, i) => (
                                        <Message key={i} name={message.name} text={message.text} thinking={state.assistantThinking} />
                                    ))}
                                    {
                                        // placeholder
                                    }
                                    <div className=" h-24"></div>
                                </div>
                            </div>
                        </div>
                    </ScrollToBottom>
                </div>
                {
                    // chat entry
                }
                <div className="absolute bottom-2 md:bottom-6 left-0 right-0 px-4 md:px-12 pb-2 pt-12 bg-gradient-to-t from-white from-50% ">
                    {(state.assistantThinking || state.isWriting) && (
                        <div className="flex mx-auto justify-center mb-2">
                            <button
                                type="button"
                                className="rounded bg-indigo-50 py-1 px-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                                onClick={handleAbort}
                            >
                                Stop generating
                            </button>
                        </div>
                    )}
                    <div className="relative flex flex-col w-full p-2 md:p-3  bg-electric-violet-200 rounded-md shadow-[0_3px_10px_rgb(162,0,255,0.2)]  ring-1 ring-electric-violet-200  focus-within:ring-2 focus-within:ring-inset focus-within:ring-electric-violet-600 border md:border-2 border-electric-violet-700">
                        <label htmlFor="prompt" className="sr-only">
                            Prompt
                        </label>
                        <TextareaAutosize
                            autoFocus
                            disabled={state.assistantThinking || state.isWriting}
                            ref={promptInput}
                            name="prompt"
                            id="prompt"
                            rows={1}
                            maxRows={6}
                            onKeyDown={handlePromptKey}
                            className="m-0 w-full resize-none border-0 bg-transparent  pr-7 focus:ring-0 focus-visible:ring-0 text-electric-violet-800 text-sm md:text-base placeholder-electric-violet-500 "
                            placeholder={`${state.assistantThinking || state.isWriting ? "Answering.." : "Send an answer.."}`}
                        />
                        <div className="absolute right-5 top-[calc(50%_-_10px)]">
                            {state.assistantThinking || state.isWriting ? (
                                <Spinner cx="animate-spin w-5 h-5 text-gray-400" />
                            ) : (
                                <SendIcon cx="w-6 h-6 text-purple-pizzazz-600 hover:text-purple-pizzazz-800 hover:cursor-pointer" onClick={handlePrompt} />
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
